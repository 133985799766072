@tailwind base;
@tailwind components;
@tailwind utilities;

.dropdown_rotate {
    transform: rotate(180deg);
}

.fade-in {
    animation: fadeIn 700ms;
    opacity: 1;
}

.slow-fade-in {
    animation: fadeIn 1400ms;
    opacity: 1;
}

.fade-out {
    animation: fadeOut 700ms;
    opacity: 0;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
}