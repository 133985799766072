.danger-html .mcnTextBlockInner {
    font-family: Arial, sans-serif;
    font-size: 1rem;
    color: #333333;
    line-height: 1.6;
    margin: 1.25rem;
}

.danger-html .mcnTextBlockInner h1,
.danger-html .mcnTextBlockInner h2 {
    color: #2c3e50;
    font-weight: bold;
    font-size: 1.5rem;
}

.danger-html .mcnTextBlockInner ul {
    list-style-type: disc;
    margin-left: 1.25rem;
}

.danger-html .mcnTextBlockInner li {
    margin-bottom: 0.625rem;
}

.danger-html .mcnTextBlockInner p {
    margin-bottom: 1.25rem;
}

.danger-html .mcnTextBlockInner hr {
    border: none;
    border-top: 0.125rem solid #DED7bf;
    width: 100%;
    margin: 1.25rem 0;
}