.tox-tinymce {
    border: 2px solid #BBAC79 !important;
    border-radius: 0% !important;
}

.mce-content-body {
    cursor: text !important;
}

.tox-editor-header,
.tox-toolbar-overlord,
.tox-toolbar__primary {
    background-color: #eeedeb !important;
    box-shadow: none !important;
}