@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-image: none;
    overflow-x: hidden;
}

html,
body,
#app,
#app>div {
    height: 100%
}


body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-variant-numeric: tabular-nums;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.postcode-select {
    margin-top: 10px;
    border: 1px solid #BBAC79;
    height: 40px;
    font-family: proxima_nova;
    max-width: 94%;
}

.pc-buttons {
    background-color: #6DC8BF !important;
    text-transform: uppercase;
    color: #40403E;
    transition-duration: 500ms;
    padding: 0.5rem 0.5rem;
    height: 40px;
    word-wrap: break-word;
    white-space: nowrap;
    flex: 1 0 20%;
}

.pc-buttons:hover {
    background-color: #BBAC79;
}

.postcode-input {
    height: 40px;
    border: 2px solid #BBAC79;
    padding: 4px;
    flex: 1 0 75%;
}

.postcode-input:focus {
    background-color: #FFEBC4;
    outline-color: #AD9B5E;
}


/* hover:bg-midasLight bg-downy  pl-5 pr-5 pt-2 pb-2 uppercase text-sm font-semibold text-tuxedo transition-all duration-500 mr-2 */

.postcode-select:focus {
    background-color: #FFEBC4;
    outline-color: #AD9B5E;
}


#root {
    min-height: 100vh;
}

input {
    transition: background-color 0.3s;
    border-radius: 5px;
}


.danger-html > ul {
    list-style-type: disc;
    margin: revert;
    padding: revert;
    font-family: "Proxima Nova", system-ui, sans-serif;
}

.danger-html > p {
    margin-bottom: 1em;
    color: black;
}

@font-face {
    font-family: 'kings_caslon';
    src: url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/kingscaslon_bd-webfont.woff2') format('woff2'),
    url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/kingscaslon_bd-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'kings_caslon';
    src: url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/kingscaslon_bdit-webfont.woff2') format('woff2'),
    url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/kingscaslon_bdit-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'kings_caslon';
    src: url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/kingscaslon_it-webfont.woff2') format('woff2'),
    url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/kingscaslon_it-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'kings_caslon';
    src: url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/kingscaslon_rg-webfont.woff2') format('woff2'),
    url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/kingscaslon_rg-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'kings_caslon_display';
    src: url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/kingscaslondisplay_it-webfont.woff2') format('woff2'),
    url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/kingscaslondisplay_it-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'kings_caslon_display';
    src: url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/kingscaslondisplay_rg-webfont.woff2') format('woff2'),
    url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/kingscaslondisplay_rg-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@layer base {

    /* Standard Proxima Nova*/
    @font-face {
        font-family: 'Proxima Nova';
        src: url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/proxima_nova_regular-webfont.woff2') format('woff2');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Proxima Nova';
        src: url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/proxima_nova_regular_italic-webfont.woff2') format('woff2'),
        url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/proxima_nova_regular_italic-webfont.woff') format('woff');
        font-weight: normal;
        font-style: italic;
    }


    /*
     Thin Proxima Nova

    */
    @font-face {
        font-family: 'Proxima Nova';
        font-weight: 100;
        font-style: normal;
        font-display: swap;
        src: url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/proxima_nova_thin-webfont.woff') format('woff');
    }

    @font-face {
        font-family: 'Proxima Nova';
        font-style: italic;
        font-weight: 100;
        font-display: swap;
        src: url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/proxima_nova_thin_italic-webfont.woff2') format('woff2');
    }

    /*
    Semi bold Nova

    */
    @font-face {
        font-family: 'Proxima Nova';
        src: url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/proxima_nova_semibold-webfont.woff2') format('woff2');
        font-weight: 600;
        font-style: normal;
    }

    @font-face {
        font-family: 'Proxima Nova';
        src: url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/proxima_nova_semibold_italic-webfont.woff2') format('woff2');
        font-weight: 600;
        font-style: italic;
    }


    /*
    Bold Nova

    */
    @font-face {
        font-family: 'Proxima Nova';
        src: url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/proxima_nova_bold-webfont.woff2') format('woff2');
        font-weight: bold;
        font-style: normal;
    }

    @font-face {
        font-family: 'Proxima Nova';
        src: url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/proxima_nova_bold_italic-webfont.woff2') format('woff2');
        font-weight: bold;
        font-style: italic;
    }

    /*
    Nova light

    */
    @font-face {
        font-family: 'proxima_nova_light';
        src: url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/proxima_nova_light-webfont.woff2') format('woff2');
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: 'Proxima Nova';
        src: url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/proxima_nova_light_italic-webfont.woff2') format('woff2'),
        url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/proxima_nova_light_italic-webfont.woff') format('woff');
        font-weight: 300;
        font-style: italic;
    }


    /*
    Extra bold

    */
    @font-face {
        font-family: 'proxima_nova_extrabold';
        src: url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/proxima_nova_extrabold-webfont.woff2') format('woff2'),
        url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/proxima_nova_extrabold-webfont.woff') format('woff');
        font-weight: 1000;
        font-style: normal;
    }

    @font-face {
        font-family: 'proxima_nova_extrabold';
        src: url('https://wc-general-bucket.s3.eu-west-1.amazonaws.com/web-fonts/proxima_nova_extrabold_italic-webfont.woff2') format('woff2');
        font-weight: 1000;
        font-style: italic;
    }



    html {
        font-family: "Proxima Nova", system-ui, sans-serif;
    }

    body {
        font-family: "Proxima Nova", system-ui, sans-serif;
    }
}

